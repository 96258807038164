import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getActivity,
  deleteActivityData,
  getActivityCount,
  getActivityExport,
} from "src/actions/activity";
import Search from "../components/Activity/Search";
import ActivityTable from "../components/Activity/ActivityTable";
import { getUsers, getGroupData } from "src/actions/user";
import { getDashboardData } from "src/actions/dashboard";
import PageHeader from "src/components/Shared/PageHeader";
import ActivityEntry from "../components/Activity/ActivityEntry";
import ModalDefault from "src/components/Shared/Modals/ModalDefault";
import { toast } from "react-toastify";
import { utils, writeFile } from "xlsx";
import ExportContainer from "../components/Shared/ExportContainer";
import moment from "moment";
import { AnimatePresence } from "framer-motion";
import NavigationCrumbs from "src/components/Navigation/NavigationCrumbs";
import { Bars4Icon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { classNames } from "src/helpers/classNames";
import ActivitySettings from "../components/Activity/ActivitySettings";

const tabs = [
  { name: "Activity Log", icon: Bars4Icon },
  { name: "Anti-Automation", icon: WrenchScrewdriverIcon },
];

function ActivitySection({
  site,
  getUsers,
  deleteActivityData,
  users,
  getGroupData,
  groups,
  getDashboardData,
  dashboards,
  getActivity,
  getActivityCount,
  getActivityExport,
  ...props
}) {
  const [count, setCount] = useState(0);
  const [activities, setActivities] = useState(0);

  const [perPage, setPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pages, setPages] = useState(1);
  const [start_date, setStateDate] = useState("");
  const [end_date, setEndDate] = useState("");

  const [user, setUser] = useState("");
  const [group, setGroup] = useState("");
  const [dashboard, setDashboard] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [exportType, setExportType] = useState("xls");

  const [deleteId, setDeleteId] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Activity Log");

  useEffect(() => {
    const ac = new AbortController();
    const loadData = async () => {
      setLoaded(false);
      try {
        const data = await getActivity(
          {
            limit: perPage,
            offset: offset * perPage,
            user_id: user,
            group_id: group,
            dashboard_id: dashboard,
            start_date: start_date,
            end_date: end_date,
          },
          ac.signal
        );
        setCount(data.meta.count);
        setActivities(data.data);
        const availablePages = Math.ceil(data.meta.count / perPage);
        setPages(availablePages);
        setLoaded(true);
      } catch (error) {
        setLoaded(true);
        console.dir(error.message);
      }
    };

    loadData();
    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, start_date, end_date, group, user, dashboard, offset]);

  const getOffsetPageCount = (offset, perPage) => {
    return !offset ? offset + 1 : offset * perPage + 1;
  };

  useEffect(() => {
    const ac = new AbortController();
    const loadDashboards = async () => {
      try {
        await getDashboardData({}, ac.signal);
      } catch (error) {
        console.dir(error.message);
      }
    };

    loadDashboards();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ac = new AbortController();
    const loadGroups = async () => {
      try {
        await getGroupData({});
      } catch (error) {
        console.dir(error.message);
      }
    };

    loadGroups();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeDel = async (e) => {
    e.preventDefault();

    try {
      const message = await deleteActivityData(deleteId);
      setDeleteId(null);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const ac = new AbortController();

    const loadUsers = async () => {
      try {
        const userCount = await getUsers({}, ac.signal);

        // eslint-disable-next-line no-unused-vars
        const availablePages = Math.ceil(userCount / perPage);
      } catch (error) {
        console.dir(error.message);
      }
    };

    loadUsers();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatMemberDate = (date) => {
    if (!date) return "";

    return moment(new Date(date)).format("LLL");
  };

  const exportData = async () => {
    const params = {
      user_id: user,
      group_id: group,
      dashboard_id: dashboard,
      start_date: start_date,
      end_date: end_date,
    };
    try {
      const res = await getActivityExport(params);

      let data = [];
      if (res.length > 0) {
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          data.push({
            Name: element?.user_id?.name,
            Email: element?.user_id?.email,
            "Activity Type": element?.type,
            "Dashboard Accessed": element?.data?.dashboard_id?.name,
            Date: formatMemberDate(element?.created_at),
          });
        }
      }

      const headings = [["Name", "Email", "Activity Type", "Dashboard Accessed", "Date"]];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
      utils.book_append_sheet(wb, ws, "Report");
      if (exportType === "xls") {
        writeFile(wb, "Activity Report.xls");
      } else {
        writeFile(wb, "Activity Report.csv");
      }
    } catch (error) {
      console.dir(error.message);
    }
  };

  return (
    <>
      <PageHeader>Activity List</PageHeader>
      <ModalDefault
        isOpen={!!deleteId}
        closeModal={() => setDeleteId(null)}
        onSubmit={removeDel}
        submitText="Delete"
        cancelText="Cancel"
        title="Delete item">
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">
          Are you sure you want to delete?
        </div>
      </ModalDefault>

      <div className="border-b border-gray-200 mb-5">
        <nav
          className="-mb-px flex space-x-8"
          aria-label="Tabs">
          {tabs.map((tab, i) => (
            <div
              key={tab.name}
              onClick={() => setSelectedTab(tab.name)}
              className={classNames(
                tab.name === selectedTab
                  ? "border-highlightColor text-highlightColor"
                  : "border-transparent text-gray-400 hover:text-gray-500 hover:border-gray-400",
                "group inline-flex items-center pb-3 px-1 border-b-2 font-medium text-sm cursor-pointer"
              )}
              aria-current={tab.name === selectedTab ? "page" : undefined}>
              <tab.icon
                className={classNames(
                  tab.name === selectedTab
                    ? "text-highlightColor"
                    : "text-gray-400 group-hover:text-gray-400",
                  "-ml-0.5 mr-2 h-5 w-5"
                )}
                aria-hidden="true"
              />
              <span>{tab.name}</span>
            </div>
          ))}
        </nav>
      </div>
      <div className="mt-4">
        {tabs.find((tab) => tab.name === selectedTab).name === "Activity Log" && (
          <>
            <Search
              setStateDate={setStateDate}
              setEndDate={setEndDate}
              count={activities.length}
              users={users}
              groups={groups}
              dashboards={dashboards}
              setPerPage={setPerPage}
              setUser={setUser}
              user={user}
              setGroup={setGroup}
              group={group}
              setDashboard={setDashboard}
              dashboard={dashboard}
              offset={offset}
              setOffset={setOffset}
            />
            <ActivityTable
              setDeleteId={setDeleteId}
              deleteId={deleteId}
              loaded={loaded}
              dataExists={activities.length > 0}>
              <AnimatePresence>
                {activities.length > 0 &&
                  activities.map((activity) => (
                    <ActivityEntry
                      key={activity._id}
                      activity={activity}
                      deleteId={deleteId}
                      setDeleteId={setDeleteId}
                      dataExists={activities.length > 0}
                    />
                  ))}
              </AnimatePresence>
            </ActivityTable>
            <NavigationCrumbs
              getOffsetPageCount={getOffsetPageCount}
              offset={offset}
              perPage={perPage}
              setPerPage={setPerPage}
              count={count}
              pages={pages}
              setOffset={setOffset}
            />
            {activities.length > 0 && (
              <ExportContainer
                exportType={exportType}
                setExportType={setExportType}
                onSubmit={exportData}></ExportContainer>
            )}
          </>
        )}
        {tabs.find((tab) => tab.name === selectedTab).name === "Anti-Automation" && (
          <>
            <ActivitySettings />
          </>
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    site: state.site,
    groups: Object.values(state.groups),
    dashboards: Object.values(state.dashboards),
    users: Object.values(state.users),
  };
};
export default connect(mapStateToProps, {
  getActivityCount,
  getUsers,
  getActivity,
  deleteActivityData,
  getGroupData,
  getDashboardData,
  getActivityExport,
})(ActivitySection);
