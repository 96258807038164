import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { logout } from "src/actions/auth";
import { getDomoDashboardList } from "src/actions/dashboard";
import { getMenuData } from "src/actions/menu";
import { setSearchText } from "src/actions/search";
import NavItem from "src/components/Navigation/NavItem";
import NavigationPreviewBox from "src/components/Preview/NavigationPreviewBox";
import AdminNavDesktop from "src/components/RoleViews/Admin/AdminNavDesktop";
import UserProfile from "src/components/Users/List/UserProfile";
import { FeatureFlagsProvider } from "src/context/FeatureFlagContext";
import { asyncLocalStorage } from "src/helpers/asyncLocalStorage";
import useOnClickOutside from "src/hooks/useOnClickOutside";
import Logo from "../components/User/Logo";
import { classNames } from "../helpers/classNames";

const UserLayout = ({ user, site, getMenuData, logout, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userNavigation, setUserNavigation] = useState([
    { name: "Your Profile", path: `/update-user/${user?._id}`, navName: "Users" },
    { name: "Change Password", path: "/change-password", navName: "Settings" },
    { name: "Sign out", path: "/" },
  ]);
  const [currentNavigation, setCurrentNavigation] = useState("");
  const [hasPreviewUserId, setHasPreviewUserId] = useState(false);
  const [rolePreviewModal, setRolePreviewModal] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => setSidebarOpen(false));

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [sidebarOpenMobile, setSidebarOpenMobile] = useState(false);
  const [loadedMenu, setLoadedMenu] = useState(false);

  const [dashboardList, setDashboardList] = useState([]);

  const updateNavigation = (navItem) => {
    setCurrentNavigation(navItem.name);
    // navigate(`/analytics/${navItem.key}`);
  };

  useEffect(() => {
    let redirectPath = "/update-user/" + user?._id;
    if (user?._id) {
      const getList = async () => {
        const responseList = await getDomoDashboardList({ user_id: user?._id });
        let list = [];
        if (responseList.length > 0) {
          for (const lt of responseList) {
            list.push({
              key: lt.dashboard?.name,
              value: lt._id,
            });
          }
        }
        list = list.map((item) => {
          return { key: item.value, name: item.key, path: "/analytics/" + item.value };
        });

        if (list.length > 0) {
          redirectPath = "/analytics/" + list[0]?.key;
        }
        setDashboardList(list);
        if (!location.pathname.includes("analytics")) {
          if (redirectPath) navigate(redirectPath);
        }
      };
      getList();
      if (user?.creation_type === "SSO_LOGIN") {
        setUserNavigation([
          { name: "Your Profile", path: `/update-user/${user?._id}`, navName: "Users" },
          { name: "Sign out", path: "/" },
        ]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id]);

  useEffect(() => {
    //     const convertNavigation = [{ name: "Pages", href: "/dashboards" }];
    //     let path = window.location.href;
    //     let navigationTab = convertNavigation.find((tab) => path.includes(tab.href));
    //     if (navigationTab) updateNavigation(navigationTab.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removePreviewUser = () => {
    asyncLocalStorage.removeItem("previewUserId");
    // window.location.reload();
    navigate("/users");
  };

  useEffect(() => {
    let previewUserId = localStorage.getItem("previewUserId");
    if (previewUserId) {
      setHasPreviewUserId(true);
    } else {
      setHasPreviewUserId(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("previewUserId")]);

  useEffect(() => {
    const ac = new AbortController();

    const loadMenuData = async () => {
      try {
        await getMenuData({}, ac.signal);
        setLoadedMenu(true);
      } catch (error) {
        console.dir(error.message);
      }
    };

    loadMenuData();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("previewUserId")]);

  useEffect(() => {
    if (loadedMenu && !user) {
      window.location.href = "/login";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    // options={dashboardList}
    // setOption={(option) => {
    //   setDashboardId(option.value);
    // }}
  }, [dashboardList]);

  return (
    <>
      <FeatureFlagsProvider>
        <div className="flex h-full flex-col">
          <Transition.Root
            show={sidebarOpenMobile}
            as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setSidebarOpenMobile}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full">
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-leftNavColor pb-4">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0">
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:border-highlightColor focus:ring-0"
                          onClick={() => setSidebarOpenMobile(false)}>
                          <span className="sr-only">Close Side Bar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="bg-headerColor pl-4">
                      <Logo />
                    </div>
                    <div className="mt-5 h-0 flex-1 overflow-y-auto">
                      <nav className="space-y-1 px-2">
                        {dashboardList.map((item) => (
                          <Fragment key={item.key}>
                            <NavItem
                              item={item}
                              activeItem={currentNavigation}
                              onClick={updateNavigation}
                            />
                          </Fragment>
                        ))}
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div
                  className="w-14 flex-shrink-0"
                  aria-hidden="true">
                  {/* Dummy element to force leftNavColor to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          <div
            className={classNames(
              "flex w-full transition-all duration-150",
              sidebarOpen ? "lg:pl-0" : ""
            )}>
            <div className="fixed top-0 z-30 flex h-16 w-full flex-shrink-0 bg-headerColor shadow-md">
              <Logo>
                <button
                  type="button"
                  className="px-4 text-headerTextColor focus:border-highlightColor focus:ring-0 lg:hidden"
                  onClick={() => setSidebarOpenMobile(true)}>
                  <span className="sr-only">Open left navigation</span>
                  <Bars3BottomLeftIcon
                    className="h-6 w-6 text-headerTextColor"
                    aria-hidden="true"
                  />
                </button>
                <button
                  type="button"
                  className="hidden border-0 px-4 text-gray-500 focus:border-headerTextColor focus:ring-0 lg:flex"
                  onClick={() => setSidebarOpen(!sidebarOpen)}>
                  <span className="sr-only">Open leftNavColor</span>
                  <Bars3BottomLeftIcon
                    className="h-6 w-6 text-headerTextColor"
                    aria-hidden="true"
                  />
                </button>
              </Logo>
              <div className="flex w-full items-center justify-end pr-6">
                <div className="ml-4 flex items-center lg:ml-6">
                  <div className="hidden sm:flex w-22 m-0 h-6 items-center text-sm font-medium text-headerTextColor">
                    {user?.name ? user?.name : null}
                  </div>

                  {/* Profile dropdown */}
                  <Menu
                    as="div"
                    className="flex items-center">
                    <Menu.Button className="ml-3 h-8 w-8 overflow-hidden rounded-full">
                      <span className="sr-only">Open user menu</span>
                      {<UserProfile user={user || {}} />}
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute top-12 right-7 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <AdminNavDesktop userNavigation={userNavigation} />
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
          <div className="relative flex h-full w-full">
            {/* Static leftNav for desktop */}
            <div
              className={classNames(
                "fixed left-0 top-16 z-20 hidden h-[calc(100%-56px)] transition-all duration-150 lg:flex lg:w-64 lg:flex-col",
                sidebarOpen ? "left-0" : "-left-64"
              )}>
              <nav className="flex h-full flex-col justify-between bg-leftNavColor px-2 py-4 shadow-md">
                <div className="grid gap-y-1 overflow-auto max-h-full scroll-smooth scrollbar">
                  {dashboardList.map((item) => (
                    <Fragment key={item.key}>
                      <NavItem
                        item={item}
                        activeItem={currentNavigation}
                        onClick={updateNavigation}
                      />
                    </Fragment>
                  ))}
                </div>
                {/* <NavItem item={{ name: "dashboard data", path: "/dashboarddata" }} /> */}
                <NavigationPreviewBox
                  rolePreviewModal={rolePreviewModal}
                  removePreviewUser={removePreviewUser}
                  user={user}
                  hasPreviewUserId={hasPreviewUserId}
                  setRolePreviewModal={setRolePreviewModal}
                />
              </nav>
            </div>
            <div
              className={classNames(
                "relative z-10 w-full pt-16 transition-all duration-100",
                sidebarOpen ? "lg:pl-64" : "lg:pl-0"
              )}>
              <div className="w-full h-full">{children}</div>
            </div>
          </div>
        </div>
      </FeatureFlagsProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    collapse: state.collapse,
    site: state.site,
    user: state.auth.user,
    searchText: state.search.text,
  };
};

export default connect(mapStateToProps, { getMenuData, logout, setSearchText })(UserLayout);
