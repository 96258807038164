import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getDashboardData } from "src/actions/dashboard";
import { getOperatorData } from "src/actions/operator";
import { manageSiteSettings } from "src/actions/site";
import AddPermissionForm from "src/components/Permissions/AddPermissionForm";
import Button from "src/components/Shared/Button";
import SimpleDropdown from "src/components/Shared/Forms/SimpleDropdown";
import { operatorNameConverter } from "src/helpers/operatorNameConverter";
import Input from "../Shared/Forms/Input";
import MultiSelect from "../Shared/Forms/MultiSelect";
import { classNames } from "src/helpers/classNames";
import { AnimatePresence } from "framer-motion";
import FilterRow from "../Permissions/FilterRow";
import { PlusIcon } from "@heroicons/react/24/outline";

const SSOServiceAttribute = ({ site, manageSiteSettings, dashboards, operators, ...props }) => {
  const [sso_role, setRole] = useState("");
  const [sso_value, setRoleValue] = useState({ _id: -1, name: "None selected" });
  const [sso_trusted_attribute, setSsoTrustedAttribute] = useState("");
  const [sso_trusted_attribute_alt, setSsoTrustedAttributeAlt] = useState({
    _id: -1,
    name: "None selected",
  });
  const [sso_trusted_attribute_value, setSsoTrustedAttributeValue] = useState("");
  const [trusted_attribute, setTrustedAttribute] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [permissionFormCount, setPermissionFormCount] = useState([0]);
  const [permissionState, setPermissionState] = useState({});
  const [groupPermissionState, setGroupPermissionState] = useState([{}]);
  const trustedAttributeArr = [
    {
      _id: "user.firstName",
      value: "user.firstName",
      name: "user.firstName",
    },
    {
      _id: "user.lastName",
      value: "user.lastName",
      name: "user.lastName",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "user.email",
    },
    {
      _id: "user.username",
      value: "user.username",
      name: "user.username",
    },
    {
      _id: "user.company",
      value: "user.company",
      name: "user.company",
    },
    {
      _id: "user.description",
      value: "user.description",
      name: "user.description",
    },
  ];

  // Load dashboards
  useEffect(() => {
    const ac = new AbortController();

    const loadDashboards = async () => {
      try {
        props.getDashboardData({}, ac.signal);
      } catch (error) {
        console.dir(error.message);
      }
    };

    loadDashboards();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load operators
  useEffect(() => {
    const ac = new AbortController();

    const loadOperators = async () => {
      try {
        props.getOperatorData({}, ac.signal);
      } catch (error) {
        // console.dir(error.message);
      }
    };

    loadOperators();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Assigned Dashbaords
  useEffect(() => {
    if (site?.permissions && site?.permissions.length) {
      const finalPermissions = {};
      const updatedStateCount = [];
      site?.permissions.forEach((permission, i) => {
        const operatorIndex = operators.findIndex((item) => item._id === permission?.operator_id);
        const trustedIndex = trustedAttributeArr.findIndex(
          (item) => item._id === permission?.trusted_attribute
        );
        if (finalPermissions[permission?.dashboard_id]) {
          finalPermissions[permission?.dashboard_id].filters.push({
            operator: {
              ...operators[operatorIndex],
              name: operatorNameConverter(operators[operatorIndex]?.name),
            },
            operator_id: permission?.operator_id,
            trustedAttribute: { ...trustedAttributeArr[trustedIndex] },
            trusted_attribute: permission?.trusted_attribute,
            column_name: permission?.column_name,
            column_value: permission?.column_value,
          });
        } else {
          let permisn = {
            dashboard_id: permission?.dashboard_id,
            filters: [
              {
                operator: {
                  ...operators[operatorIndex],
                  name: operatorNameConverter(operators[operatorIndex]?.name),
                },
                operator_id: permission?.operator_id,
                trustedAttribute: { ...trustedAttributeArr[trustedIndex] },
                trusted_attribute: permission?.trusted_attribute,
                column_name: permission?.column_name,
                column_value: permission?.column_value,
              },
            ],
          };
          finalPermissions[permission?.dashboard_id] = permisn;
        }
      });

      let permission = {};
      Object.values(finalPermissions).forEach((permiss, i) => {
        permission[i] = permiss;
        updatedStateCount.push(i);
        return true;
      });

      setPermissionState(permission);
      setPermissionFormCount(updatedStateCount);
    } else if (site?.permissions && !site?.permissions.length) {
      setPermissionState({});
      setPermissionFormCount([0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?.permissions, operators]);

  // Assigned Group's Pages Permissions
  useEffect(() => {
    if (site?.group_permissions && site?.group_permissions.length) {
      const finalGroupPermissions = [];
      site?.group_permissions.forEach((permission, i) => {
        const operatorIndex = operators.findIndex((item) => item._id === permission?.operator_id);
        const trustedIndex = trustedAttributeArr.findIndex(
          (item) => item._id === permission?.trusted_attribute
        );

        finalGroupPermissions.push({
          operator: {
            ...operators[operatorIndex],
            name: operatorNameConverter(operators[operatorIndex]?.name),
          },
          operator_id: permission?.operator_id,
          trustedAttribute: { ...trustedAttributeArr[trustedIndex] },
          trusted_attribute: permission?.trusted_attribute,
          column_name: permission?.column_name,
          column_value: permission?.column_value,
        });
      });

      setGroupPermissionState(finalGroupPermissions);
    } else if (site?.group_permissions && !site?.group_permissions.length) {
      setGroupPermissionState([{}]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?.group_permissions, operators]);

  useEffect(() => {
    if (site?.sso_role) {
      let isUser = site?.sso_role === "user";
      setRoleValue({
        _id: isUser ? 0 : 1,
        value: isUser ? "user" : "admin",
        name: isUser ? "User" : "Admin",
      });
    }
    setRole(site?.sso_role);
  }, [site?.sso_role]);

  useEffect(() => {
    if (site?.sso_trusted_attribute.includes("user.")) {
      setSsoTrustedAttributeAlt({
        _id: site?.sso_trusted_attribute,
        value: site?.sso_trusted_attribute,
        name: site?.sso_trusted_attribute,
      });
    }
    setSsoTrustedAttribute(site?.sso_trusted_attribute);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?.sso_trusted_attribute]);

  useEffect(() => {
    setSsoTrustedAttributeValue(site?.sso_trusted_attribute_value);
  }, [site?.sso_trusted_attribute_value]);

  useEffect(() => {
    setTrustedAttribute(site?.trusted_attribute);
  }, [site?.trusted_attribute]);

  useEffect(() => {
    if (site?.groups && site?.groups.length > 0) {
      setSelectedGroups(site?.groups);
    }
  }, [site?.groups]);

  const addNewPermission = (e) => {
    e.preventDefault();
    if (permissionFormCount.length > 0) {
      setPermissionFormCount([...permissionFormCount, permissionFormCount.slice(-1)[0] + 1]);
    } else {
      setPermissionFormCount([0]);
    }
  };

  const updateSSORole = async () => {
    try {
      let permission = [];
      let orderValue = 1;
      Object.values(permissionState).map((pe) => {
        pe.filters.map((flt) => {
          permission.push({
            dashboard_id: pe.dashboard_id,
            operator_id: flt.operator_id,
            column_name: flt.column_name,
            column_value: flt.column_value,
            trusted_attribute: flt.trusted_attribute,
            ordering: orderValue,
          });
          orderValue++;
          return true;
        });
        return true;
      });

      let groupPermission = [];
      for (const value of groupPermissionState) {
        groupPermission.push({
          operator_id: value.operator_id,
          column_name: value.column_name,
          column_value: value.column_value,
          trusted_attribute: value.trusted_attribute,
        });
      }

      const message = await manageSiteSettings({
        sso_role: sso_role,
        groups: selectedGroups,
        trusted_attribute: trusted_attribute,
        sso_trusted_attribute: sso_trusted_attribute,
        sso_trusted_attribute_value: sso_trusted_attribute_value,
        permissions: permission,
        group_permissions: groupPermission,
      });
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      Object.values(permissionState),
      result.source.index,
      result.destination.index
    );
    setPermissionState({ ...items });
  };

  return (
    <>
      <div className="shadow bg-white rounded-md mt-4 p-6 flex flex-col gap-y-3">
        <h5 className="text-base font-medium mb-4">Map Attributes</h5>
        <div className="w-full h-[1px] bg-gray-200" />
        <div className="w-full flex flex-col gap-y-5">
          {/* <div className="pl-7 flex flex-wrap items-end space-x-3 py-5">
            <div className="relative py-2.5 text-sm font-medium text-gray-700">Assign as</div>
            <div className="relative w-36">
              <SimpleDropdown
                label="Role"
                options={[
                  {
                    _id: 0,
                    value: "user",
                    name: "User",
                  },
                  {
                    _id: 1,
                    value: "admin",
                    name: "Admin",
                  },
                ]}
                selected={sso_value}
                setSelected={(e) => {
                  setRole(e.value);
                  setRoleValue(e);
                }}
              />
            </div>
            <div className="relative py-2.5 text-sm font-medium text-gray-700">when</div>
            <div className="relative w-48">
              <SimpleDropdown
                label="Trusted attribute"
                options={[
                  {
                    _id: "user.firstName",
                    value: "user.firstName",
                    name: "user.firstName",
                  },
                  {
                    _id: "user.lastName",
                    value: "user.lastName",
                    name: "user.lastName",
                  },
                  {
                    _id: "user.email",
                    value: "user.email",
                    name: "user.email",
                  },
                  {
                    _id: "user.username",
                    value: "user.username",
                    name: "user.username",
                  },
                  {
                    _id: "user.company",
                    value: "user.company",
                    name: "user.company",
                  },
                  {
                    _id: "user.description",
                    value: "user.description",
                    name: "user.description",
                  },
                ]}
                selected={sso_trusted_attribute_alt}
                setSelected={(e) => {
                  setSsoTrustedAttribute(e.value);
                  setSsoTrustedAttributeAlt(e);
                }}
              />
            </div>
            <div className="relative py-2.5 text-sm font-medium text-gray-700">equals</div>
            <div className="relative w-48">
              <div className="grow">
                <Input
                  name="sso_trusted_attribute_value"
                  label={""}
                  value={sso_trusted_attribute_value}
                  onChange={(e) => {
                    setSsoTrustedAttributeValue(e.target.value);
                  }}
                />
              </div>
            </div>
          </div> */}
          {/* <div className="w-full h-[1px] bg-gray-200" /> */}
          <div className="pl-5 grid grid-cols-1 gap-4 w-full py-5">
            {permissionFormCount.length > 0 ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}>
                      {permissionFormCount.map((count, i) => {
                        return (
                          <Draggable
                            key={i}
                            draggableId={`${i}`}
                            index={i}>
                            {(provided, snapshot) => (
                              <AddPermissionForm
                                innerRef={provided.innerRef}
                                draggableProps={provided.draggableProps}
                                dragHandleProps={provided.dragHandleProps}
                                key={i}
                                index={count}
                                dashboards={dashboards}
                                operators={operators?.map((operator) => {
                                  return {
                                    ...operator,
                                    name: operatorNameConverter(operator.name),
                                    id: operator._id,
                                  };
                                })}
                                formCount={permissionFormCount}
                                setFormCount={setPermissionFormCount}
                                state={permissionState}
                                setState={setPermissionState}
                                usesTrustedValue={true}
                              />
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <span className="text-gray-400">No permissions</span>
            )}
            <div className="flex justify-end">
              <button className="h-full inline-flex items-center justify-center rounded-md border px-4 py-2 text-regular font-medium focus:ring-2 focus:border-highlightColor bg-white text-gray-500 border-gray-400 hover:shadow-sm transition-all duration-75 sm:w-auto">
                <div
                  className="flex items-center justif-center gap-x-2"
                  onClick={addNewPermission}>
                  Add permission
                </div>
              </button>
            </div>
          </div>
          <div className="w-full h-[1px] bg-gray-200" />
          <div className="pl-5 grid grid-cols-1 gap-4 w-full">
            <div className="grid grid-cols-4 gap-4">
              <div className="pl-4 col-span-4 sm:col-span-3 lg:col-span-2">
                <MultiSelect
                  options={[
                    {
                      _id: "user.firstName",
                      value: "user.firstName",
                      name: "user.firstName",
                    },
                    {
                      _id: "user.lastName",
                      value: "user.lastName",
                      name: "user.lastName",
                    },
                    {
                      _id: "user.email",
                      value: "user.email",
                      name: "user.email",
                    },
                    {
                      _id: "user.username",
                      value: "user.username",
                      name: "user.username",
                    },
                    {
                      _id: "user.company",
                      value: "user.company",
                      name: "user.company",
                    },
                    {
                      _id: "user.description",
                      value: "user.description",
                      name: "user.description",
                    },
                  ]}
                  title="Assign groups"
                  selectedOptions={selectedGroups}
                  setSelectedOptions={setSelectedGroups}></MultiSelect>
              </div>
            </div>
            <div className="relative px-4 rounded-md border-invisible transition-colors duration-300 group bg-white/90">
              <div className="mb-2 block text-sm font-semibold text-gray-700">
                Apply these filters to all pages within the group.
              </div>
              <div className={classNames("px-2 lg:max-w-[1200px] duration-300 max-h-[2000px]")}>
                <div className="flex flex-col gap-y-2 relative">
                  <AnimatePresence>
                    <>
                      {groupPermissionState.map((count, i) => (
                        <FilterRow
                          key={"group_filter_" + i}
                          trustedAttributeArr={trustedAttributeArr}
                          usesDashboard={false}
                          usesTrustedValue={true}
                          count={count}
                          i={i}
                          state={groupPermissionState}
                          removeFilter={() => {
                            setGroupPermissionState(
                              groupPermissionState.filter((gp, gpi) => gpi !== i)
                            );
                          }}
                          setState={setGroupPermissionState}
                          operators={operators?.map((operator) => {
                            return {
                              ...operator,
                              name: operatorNameConverter(operator.name),
                              id: operator._id,
                            };
                          })}
                        />
                      ))}
                      <div className="mt-4 mb-5 flex items-center justify-end pr-6">
                        <Button
                          styleType="gray"
                          onClick={() => setGroupPermissionState([...groupPermissionState, {}])}>
                          <PlusIcon className="h-4 w-4" />
                          Add filter
                        </Button>
                      </div>
                    </>
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center w-full pt-6 space-x-4">
        <Button styleType="gray">Cancel</Button>
        <Button onClick={updateSSORole}>Save</Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    dashboards: state.dashboards,
    operators: Object.values(state.operators),
  };
};
export default connect(mapStateToProps, { manageSiteSettings, getDashboardData, getOperatorData })(
  SSOServiceAttribute
);
