import { XMarkIcon } from "@heroicons/react/24/solid";
import { motion, useIsPresent } from "framer-motion";
import Input from "src/components/Shared/Forms/Input";
import SimpleDropdown from "src/components/Shared/Forms/SimpleDropdown";
import { classNames } from "src/helpers/classNames";

export default function FilterRow({ trustedAttributeArr, usesTrustedValue = false, usesDashboard = true, operators = [], dashboardType = "dashboard", count, i, state, index, removeFilter = () => { }, setState = () => { } }) {
  let isPresent = useIsPresent();

  let generateStyle = () => {
    if (isPresent) {
      return {
        position: "relative",
      };
    } else {
      return {
        position: "absolute",
        display: "flex",
        alignItems: "center",
      };
    }
  };

  return (
    <motion.div
      key={i}
      className="relative w-full flex gap-x-3 mr-8 sm:pl-24 pr-6"
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      transition={{ opacity: { duration: 0.2 } }}
      style={generateStyle}
    >
      {dashboardType === "custom" && <div className="absolute w-full h-full top-0 left-0 z-10 cursor-not-allowed opacity-60 bg-white"></div>}
      {/* Column name */}
      <div className="grow">
        <Input
          name="column-name"
          label={"Column name"}
          value={count.column_name || ""}
          onChange={(e) => {
            if (!usesDashboard) {
              setState([
                ...state.map((filter, j) => {
                  if (j === i) {
                    return { ...filter, column_name: e.target.value };
                  } else {
                    return filter;
                  }
                })
              ])
            } else {
              setState({
                ...state,
                [index]: {
                  ...state[index],
                  filters: state[index].filters.map((filter, j) => {
                    if (j === i) {
                      return { ...filter, column_name: e.target.value };
                    } else {
                      return filter;
                    }
                  }),
                },
              });
            }
          }}
        />
      </div>
      {/* Operator */}
      <div className="w-[110px]">
        <SimpleDropdown
          label={"Operator"}
          options={operators}
          selected={count.operator}
          setSelected={(e) => {
            if (!usesDashboard) {
              setState([
                ...state.map((filter, j) => {
                  if (j === i) {
                    const operatorIndex = operators.findIndex((item) => item._id === e._id);
                    return { ...filter, operator_id: e._id, operator: operators[operatorIndex] };
                  } else {
                    return filter;
                  }
                })
              ])
            } else {
              setState({
                ...state,
                [index]: {
                  ...state[index],
                  filters: state[index].filters.map((filter, j) => {
                    if (j === i) {
                      const operatorIndex = operators.findIndex((item) => item._id === e._id);
                      return { ...filter, operator_id: e._id, operator: operators[operatorIndex] };
                    } else {
                      return filter;
                    }
                  }),
                },
              });
            }
          }}
        />
      </div>
      {/* Column value */}
      {usesTrustedValue ? (
        <div className="grow">
          <SimpleDropdown
            label="Trusted attribute"
            options={trustedAttributeArr}
            selected={count.trustedAttribute}
            setSelected={(e) => {
              if (!usesDashboard) {
                setState([
                  ...state.map((filter, j) => {
                    if (j === i) {
                      const index = trustedAttributeArr.findIndex((item) => item._id === e._id);
                      return { ...filter, trusted_attribute: e._id, trustedAttribute: trustedAttributeArr[index] };
                    } else {
                      return filter;
                    }
                  })
                ])
              } else {
                setState({
                  ...state,
                  [index]: {
                    ...state[index],
                    filters: state[index].filters.map((filter, j) => {
                      if (j === i) {
                        const index = trustedAttributeArr.findIndex((item) => item._id === e._id);
                        return { ...filter, trusted_attribute: e._id, trustedAttribute: trustedAttributeArr[index] };
                      } else {
                        return filter;
                      }
                    }),
                  },
                });
              }
            }}
          />
        </div>
      ) : (
        <div className="grow">
          <Input
            name="column-value"
            // label={index === 0 ? "Column value" : ""}
            label={"Column value"}
            value={count.column_value || ""}
            onChange={(e) => {
              if (!usesDashboard) {
                setState([
                  ...state.map((filter, j) => {
                    if (j === i) {
                      return { ...filter, column_value: e.target.value };
                    } else {
                      return filter;
                    }
                  })
                ])
              } else {
                setState({
                  ...state,
                  [index]: {
                    ...state[index],
                    filters: state[index].filters.map((filter, j) => {
                      if (j === i) {
                        return { ...filter, column_value: e.target.value };
                      } else {
                        return filter;
                      }
                    }),
                  },
                });
              }
            }}
          />
        </div>
      )}
      <div className="flex items-end">
        <button type="button" className={classNames("h-[34px] rounded-md text-gray-400 hover:text-gray-500 focus:outline-none", i === 0 ? "opacity-0" : "")} disabled={i === 0} onClick={(e) => i > removeFilter(i)}>
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </motion.div>
  );
}
