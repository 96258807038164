import moment from "moment";
import QuickMenu from "src/components/Shared/QuickMenu";
import { motion, useIsPresent } from "framer-motion";

function ActivityEntry({ activity, setDeleteId }) {
  const formatMemberDate = (date, length = "long") => {
    if (!date) return "";
    if (length === "long") {
      return moment(new Date(date)).format("LLLL");
    } else {
      return {
        date: moment(new Date(date)).format("DD/MM/YYYY"),
        time: moment(new Date(date)).format("h:mm:ssA"),
      };
    }
  };

  let isPresent = useIsPresent();

  let generateStyle = () => {
    if (isPresent) {
      return {
        position: "relative",
      };
    } else {
      return {
        position: "absolute",
        display: "flex",
        alignItems: "center",
      };
    }
  };

  return (
    <motion.tr
      key={activity?._id}
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      transition={{ opacity: { duration: 0.2 } }}
      style={generateStyle}>
      <td className="w-1/3 whitespace-pre-wrap break-all py-0.5 px-3 text-xs text-gray-500 sm:table-cell md:w-1/5 sm:py-3 sm:text-sm">
        <div className="text-gray-900 flex xl:flex-row flex-col gap-x-4">
          {activity?.user_id ? (
            <>
              <p>{activity?.user_id?.name}</p>
              <p className="text-gray-400">{activity?.user_id?.email}</p>
            </>
          ) : (
            <p className="text-gray-400">*User Deleted*</p>
          )}
        </div>
      </td>
      <td className="lg:w-1/5 hidden whitespace-pre-wrap break-all py-0.5 px-3 text-xs text-gray-500 sm:py-3 md:text-sm lg:table-cell">
        <div className="text-gray-900">{activity?.type}</div>
      </td>
      <td className="w-1/3 whitespace-pre-wrap break-all py-0.5 px-3 text-xs text-gray-500 sm:table-cell md:w-1/5 sm:py-3 sm:text-sm">
        <div className="text-gray-900">{activity?.data?.dashboard_id?.name}</div>
      </td>
      <td className="w-1/4 whitespace-pre-wrap break-all py-0.5 px-3 text-xs text-gray-500 sm:table-cell lg:w-1/5 sm:py-3 sm:text-sm">
        <div className="text-gray-900 2xl:block hidden">
          {formatMemberDate(activity?.created_at)}
        </div>
        <div className="text-gray-900 flex flex-col 2xl:hidden lg:flex-row lg:gap-x-3">
          {formatMemberDate(activity?.created_at, "short").date}{" "}
          <span className="pl-0 text-gray-400">
            {formatMemberDate(activity?.created_at, "short").time}
          </span>
        </div>
      </td>
      <td className="w-1/6 table-cell whitespace-nowrap py-3 pl-3 pr-4 text-xs font-medium sm:text-sm">
        <div className="flex justify-end">
          <QuickMenu
            items={[
              {
                name: "Delete",
                onClick: () => {
                  setDeleteId(activity._id);
                },
                color: "text-red-500",
              },
            ]}
          />
        </div>
      </td>
    </motion.tr>
  );
}

export default ActivityEntry;
